import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Tooltip,
  Chip,
  Box,
} from '@mui/material';
import { VerifiedUser } from '@mui/icons-material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { Lease } from '../types/Lease';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../../theme/global.css';
import { auth } from '../../config/firebase-config';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getStreetName } from '../utils/formatAddress';
import { brand, green, red } from '../../theme/theme';
import { formatDate } from '../utils/formatTimestamps';
import { AutoAwesome } from '@mui/icons-material';
import { yellow } from '@mui/material/colors';
import PersonIcon from '@mui/icons-material/Person';

interface LeaseCardProps {
  lease: Lease;
  onClick: (lease: Lease) => void;
}

const LeaseCard = ({ lease, onClick }: LeaseCardProps) => {
  const [user] = useAuthState(auth);
  const bidExists = Boolean(lease.has_bid);
  const isActive = Boolean(lease.is_active);
  const isBooked = Boolean(lease.booked);
  const hasPromotion = Boolean(lease.promotions && lease.promotions.length > 0);
  // const outsideViewer =
  //   !user || (lease.host_id !== user.uid && lease.guest_id !== user.uid);
  const startDate =
    new Date(lease.start_date) < new Date()
      ? 'Present'
      : formatDate(lease.start_date);

  return (
    <Card
      raised
      sx={{
        width: { xs: '100%', sm: '95%' },
        height: 'auto', // Allow dynamic height to prevent cut-off
        overflow: 'hidden',
        borderRadius: '20px',
        backgroundColor: 'transparent',
        backdropFilter: 'blur(24px)',
        boxShadow: 'none',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        // opacity: outsideViewer && isBooked ? 0.7 : 1,
        // pointerEvents: outsideViewer && isBooked ? 'none' : 'auto',
      }}
    >
      <div style={{ position: 'relative' }}>
        <Swiper
          modules={[Navigation, Pagination]}
          navigation={lease.image_urls && lease.image_urls.length > 1}
          pagination={{ clickable: true }}
          spaceBetween={0}
          slidesPerView={1}
          loop
          style={{ width: '100%', height: '300px', borderRadius: '12px' }}
          className="custom-swiper"
          lazyPreloadPrevNext={1}
          //preloadImages={true}
          //updateOnImagesReady={true}
        >
          {lease.image_urls && lease.image_urls.length > 0 ? (
            lease.image_urls.map((image, idx) => (
              <SwiperSlide key={idx}>
                <img
                  rel="preload"
                  src={image}
                  onClick={() => onClick(lease)}
                  alt={`Lease ${idx + 1}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '12px 12px 0 0',
                  }}
                  loading="eager" // Changed to eager loading for faster rendering
                />
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide>
              <img
                src="/No_Image_Available.jpg"
                alt="User uploaded 0 images"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '12px 12px 0 0',
                }}
              />
            </SwiperSlide>
          )}
        </Swiper>
      </div>
      {/* Lease Info */}
      <CardContent
        sx={{ padding: '16px', flex: 1 }}
        onClick={() => onClick(lease)}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {lease.verified && (
            <Tooltip title="User is verified">
              <VerifiedUser
                sx={{
                  color: '#1976d2',
                  fontSize: '1.3rem', // Consistent icon size
                }}
              />
            </Tooltip>
          )}
          <Typography variant="h6" color="primary" noWrap>
            {!user
              ? `Place on ${getStreetName(lease.address)}`
              : lease.address.split(',')[0]}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: 'grey', fontSize: '0.8rem' }}
            noWrap
          >
            {lease.address.split(',').slice(1, 3).join(', ')}
          </Typography>
        </div>

        <Typography variant="body2" color="text.secondary" noWrap>
          {startDate} - {formatDate(lease.end_date)}
        </Typography>
        <Typography variant="body2" color="text.secondary" noWrap>
          {lease.num_rooms_for_lease}{' '}
          {lease.num_rooms_for_lease > 1 ? 'beds' : 'bed'} –{' '}
          <span style={{ fontWeight: 'bold' }}>${lease.price}</span> /{' '}
          {lease.price_unit}
          <Box
            component="span"
            sx={{ display: 'inline-flex', ml: 1 }} // container around icons
          >
            <Tooltip
              title={`${lease.num_roommates_present_during_dublease} roommates`}
            >
              <Box component="span">
                {Array.from({
                  length: lease.num_roommates_present_during_dublease,
                }).map((_, i) => (
                  <PersonIcon
                    key={i}
                    fontSize="inherit"
                    sx={{ verticalAlign: 'text-bottom' }} // ensure icons match text baseline
                  />
                ))}
              </Box>
            </Tooltip>
          </Box>
        </Typography>
      </CardContent>
      {/* Chips */}
      <CardContent>
        {!isActive && bidExists && (
          <Chip
            label="Active Offer"
            color="info"
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              fontSize: '13px',
              zIndex: 10,
              fontWeight: 'bold',
              backgroundColor: brand[50],
              color: brand[400],
              border: '1px solid #1976d2',
            }}
          />
        )}
        {isActive && !isBooked && (
          <Chip
            label="In Progress"
            color="success"
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              fontSize: '13px',
              zIndex: 10,
              fontWeight: 'bold',
              backgroundColor: green[50],
              color: green[500],
              border: `1px solid ${green[500]}`,
            }}
          />
        )}
        {isBooked && (
          <Chip
            label="Booked"
            color="error"
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              fontSize: '13px',
              zIndex: 10,
              fontWeight: 'bold',
              backgroundColor: red[50],
              color: red[500],
              border: `1px solid ${red[500]}`,
            }}
          />
        )}
        {hasPromotion && (
          <Chip
            label={
              <Box display="flex" alignItems="center">
                <AutoAwesome fontSize="small" sx={{ mr: 0.5 }} />
                {lease.promotions}
                <AutoAwesome fontSize="small" sx={{ ml: 0.5 }} />
              </Box>
            }
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              fontSize: '13px',
              zIndex: 10,
              fontWeight: 'bold',
              backgroundColor: yellow[50],
              color: yellow[800],
              border: `1px solid ${yellow[800]}`,
            }}
          />
        )}
      </CardContent>
      {/* Button */}
      <CardContent
        sx={{
          borderTop: '1px solid #eee', // Light separator
          padding: '8px 16px', // Reduce padding for tight spacing
          marginTop: '-45px', // Pull it closer to content
        }}
      >
        {lease.is_owner ? (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => onClick(lease)}
            fullWidth
          >
            View My Listing
          </Button>
        ) : (
          <div></div>
        )}
      </CardContent>
    </Card>
  );
};

export default LeaseCard;
