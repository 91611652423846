import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Snackbar, Alert, Slide, useMediaQuery } from '@mui/material';
import {
  CheckCircle,
  Error as ErrorIcon,
  Warning,
  Info,
} from '@mui/icons-material';
import { keyframes } from '@mui/system';
import theme from '../../../theme/theme';

// Keyframes for light icon bounce animation
const bounce = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
`;

// Type for an action message
interface ActionMessage {
  id: number;
  message: string;
  severity: 'success' | 'error' | 'warning' | 'info';
}

// Context interface
interface ActionMessageContextProps {
  showMessage: (
    message: string,
    severity: 'success' | 'error' | 'warning' | 'info'
  ) => void;
  children?: ReactNode;
}

const ActionMessageContext = createContext<
  ActionMessageContextProps | undefined
>(undefined);

// Custom hook to use the context
export const useActionMessage = () => {
  const context = useContext(ActionMessageContext);
  if (!context) {
    throw new Error(
      'useActionMessage must be used within an ActionMessageProvider'
    );
  }
  return context;
};

// Function to get animated custom icon for each severity
const getIcon = (severity: 'success' | 'error' | 'warning' | 'info') => {
  const iconStyles = {
    animation: `${bounce} 0.6s ease`,
  };
  switch (severity) {
    case 'success':
      return <CheckCircle style={{ ...iconStyles, color: '#4caf50' }} />;
    case 'error':
      return <ErrorIcon style={{ ...iconStyles, color: '#f44336' }} />;
    case 'warning':
      return <Warning style={{ ...iconStyles, color: '#ff9800' }} />;
    case 'info':
      return <Info style={{ ...iconStyles, color: '#2196f3' }} />;
    default:
      return null;
  }
};

// Default SlideTransition for desktop (slides from bottom)
const SlideTransition = (props: any) => {
  return <Slide {...props} direction="up" />;
};

// ActionMessageProvider component
export const ActionMessageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [messages, setMessages] = useState<ActionMessage[]>([]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Define a mobile-specific SlideTransition (slides from top)
  const mobileSlideTransition = (props: any) => {
    return <Slide {...props} direction="down" />;
  };

  // Create a new message with a unique id (using timestamp)
  const showMessage = (
    message: string,
    severity: 'success' | 'error' | 'warning' | 'info'
  ) => {
    const id = Date.now();
    setMessages((prev) => [...prev, { id, message, severity }]);
  };

  // Handler to remove a message from the stack
  const handleClose = (id: number) => {
    setMessages((prev) => prev.filter((msg) => msg.id !== id));
  };

  // Custom styles for each severity with gradient backgrounds
  const getCustomStyles = (
    severity: 'success' | 'error' | 'warning' | 'info'
  ) => {
    switch (severity) {
      case 'success':
        return {
          background: 'linear-gradient(90deg, #4caf50, #81c784)',
          color: '#fff',
        };
      case 'error':
        return {
          background: 'linear-gradient(90deg, #f44336, #ef5350)',
          color: '#fff',
        };
      case 'warning':
        return {
          background: 'linear-gradient(90deg, #ff9800, #ffb74d)',
          color: '#fff',
        };
      case 'info':
        return {
          background: 'linear-gradient(90deg, #2196f3, #64b5f6)',
          color: '#fff',
        };
      default:
        return {};
    }
  };

  return (
    <ActionMessageContext.Provider value={{ showMessage }}>
      {children}
      {messages.map((msg, index) => (
        <Snackbar
          key={msg.id}
          open={true}
          autoHideDuration={4000}
          onClose={() => handleClose(msg.id)}
          TransitionComponent={
            isMobile ? mobileSlideTransition : SlideTransition
          }
          anchorOrigin={
            isMobile
              ? { vertical: 'top', horizontal: 'center' }
              : { vertical: 'bottom', horizontal: 'center' }
          }
          style={
            isMobile
              ? { top: `${20 + index * 95}px` }
              : { bottom: `${20 + index * 70}px` }
          }
        >
          <Alert
            onClose={() => handleClose(msg.id)}
            severity={msg.severity}
            icon={getIcon(msg.severity)}
            sx={{
              ...getCustomStyles(msg.severity),
              boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
              borderRadius: '12px',
              padding: '12px 24px',
              fontWeight: 500,
              textAlign: 'center',
              animation: 'fadeIn 0.5s ease',
            }}
          >
            {msg.message}
          </Alert>
        </Snackbar>
      ))}
    </ActionMessageContext.Provider>
  );
};
