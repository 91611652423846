import { Box, Typography, Skeleton, Avatar, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
// import { auth } from '../../../config/firebase-config';
import { Lease } from '../../types/Lease';
import { Profile } from '../../types/Profile';
import { brand } from '../../../theme/theme';
import { VerifiedUser } from '@mui/icons-material';
// import { useAuthState } from 'react-firebase-hooks/auth';
import { getUserProfile } from '../../../api/userService';
import SchoolIcon from '@mui/icons-material/School';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import BookIcon from '@mui/icons-material/Book';

interface UserDetailsTabProps {
  lease: Lease;
}

const UserDetailsTab = ({ lease }: UserDetailsTabProps) => {
  const [profileData, setProfileData] = useState<Profile | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadProfileData = async () => {
      if (lease && lease.owner_id) {
        try {
          setLoading(true);
          const data = await getUserProfile(lease.owner_id);
          setProfileData(data);
        } catch (error) {
          console.error('Error fetching profile data:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    loadProfileData();
  }, [lease]);

  if (loading || !profileData) {
    return (
      <Skeleton
        animation="wave"
        variant="rectangular"
        height={300}
        sx={{ borderRadius: '8px' }}
      />
    );
  }

  const {
    first_name,
    last_name,
    university,
    year,
    other_year,
    gender,
    major,
    verified,
    profile_picture_url,
  } = profileData;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on xs screens
        alignItems: { xs: 'center', sm: 'flex-start' }, // Center align on xs screens
        textAlign: { xs: 'center', sm: 'left' }, // Center text alignment on xs screens
        p: { xs: 1, sm: 2 }, // Adjust padding for smaller screens
        border: '1px solid #ddd',
        borderRadius: '15px',
        bgcolor: '#fff',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        mt: 2,
      }}
    >
      {/* Left Section: Profile Picture */}
      <Avatar
        rel="preload"
        src={profile_picture_url || '/default-profile.png'}
        alt={`${first_name}`}
        sx={{
          width: { xs: 60, sm: 80 }, // Smaller avatar size on xs screens
          height: { xs: 60, sm: 80 },
          border: '2px solid #ddd',
          mb: { xs: 2, sm: 0 }, // Add bottom margin on xs screens
        }}
      />

      {/* Right Section: User Details */}
      <Box sx={{ ml: { xs: 0, sm: 2 }, m: 1 }}>
        {' '}
        {/* Remove left margin on xs screens */}
        {/* Name and Gender */}
        <Typography
          variant="h6"
          sx={{
            fontWeight: 600,
            color: brand[400],
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'center', sm: 'flex-start' }, // Center align text on xs screens
          }}
        >
          {first_name}
          {verified && (
            <Tooltip title="User is verified">
              <VerifiedUser
                sx={{
                  color: '#1976d2',
                  fontSize: '1.3rem', // Smaller size
                  verticalAlign: 'text-top', // Align with the text
                  ml: 1,
                }}
              />
            </Tooltip>
          )}
          {gender && gender !== 'Other' && gender !== 'Dont want to share' && (
            <Typography
              variant="body2"
              sx={{
                ml: 1,
                fontWeight: 500,
                color: brand[500],
              }}
            >
              ({gender})
            </Typography>
          )}
        </Typography>
        {/* Year, University, and Major */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on xs screens
            alignItems: { xs: 'center', sm: 'flex-start' }, // Center align items on xs screens
            textAlign: { xs: 'center', sm: 'left' }, // Center text alignment on xs screens
            justifyContent: 'center',
            gap: { xs: 1, sm: 2 },
            mt: 1,
          }}
        >
          {/* Year */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CalendarTodayIcon
              fontSize="small"
              sx={{ color: brand[500], mr: 0.5 }}
            />
            <Typography
              variant="body2"
              sx={{
                color: brand[500],
              }}
            >
              {other_year || year || 'Year not provided'}
            </Typography>
          </Box>

          {/* University */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SchoolIcon fontSize="small" sx={{ color: brand[500], mr: 0.5 }} />
            <Typography
              variant="body2"
              sx={{
                color: brand[500],
              }}
            >
              {university || 'University not provided'}
            </Typography>
          </Box>

          {/* Major */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <BookIcon fontSize="small" sx={{ color: brand[500], mr: 0.5 }} />
            <Typography
              variant="body2"
              sx={{
                color: brand[500],
              }}
            >
              {major || 'Major not provided'}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserDetailsTab;
