import React from 'react';
import { Grid, TextField, MenuItem, Typography } from '@mui/material';
import { brand } from '../../../theme/theme';

interface AdditionalInformationProps {
  profileData: any;
  handleSwitchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSocialMediaChange: (platform: string, url: string) => void;
  handleAddSocialMedia: () => void;
}

const AdditionalInformation: React.FC<AdditionalInformationProps> = ({
  profileData,
  handleSwitchChange,
  handleChange,
  handleSocialMediaChange,
  handleAddSocialMedia,
}) => (
  <>
    <Typography variant="h6" sx={{ color: brand[500] }} gutterBottom>
      Additional Information
    </Typography>

    <Typography
      variant="subtitle1"
      color="textSecondary"
      gutterBottom
      sx={{ mb: 2, fontStyle: 'italic' }}
    >
      Sharing this info increases trust with other users and boosts engagement
    </Typography>

    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          label="University"
          name="university"
          value={profileData.university}
          onChange={handleChange}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          select
          label="Gender"
          name="gender"
          value={profileData.gender}
          onChange={handleChange}
          fullWidth
          required
        >
          \<MenuItem value="Male">Male</MenuItem>
          <MenuItem value="Female">Female</MenuItem>
          <MenuItem value="Other">Other</MenuItem>
          <MenuItem value="Dont want to share">Don’t want to share</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Major"
          name="major"
          value={profileData.major}
          onChange={handleChange}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          select
          label="Year in School"
          name="year"
          value={profileData.year}
          onChange={handleChange}
          fullWidth
          required
        >
          <MenuItem value="Freshman">Freshman</MenuItem>
          <MenuItem value="Sophomore">Sophomore</MenuItem>
          <MenuItem value="Junior">Junior</MenuItem>
          <MenuItem value="Senior">Senior</MenuItem>
          <MenuItem value="Grad">Grad</MenuItem>
          <MenuItem value="Alum">Alum</MenuItem>
          <MenuItem value="Other">Other</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          select
          label="How did you hear about us?"
          name="inbound_channel"
          value={profileData.inbound_channel}
          onChange={handleChange}
          fullWidth
          required
        >
          <MenuItem value="Club Email">Club Email</MenuItem>
          <MenuItem value="University Email">University Email</MenuItem>
          <MenuItem value="Housing Company Email">
            Housing Company Email
          </MenuItem>
          <MenuItem value="Reddit">Reddit</MenuItem>
          <MenuItem value="Facebook Groupchat">Facebook Groupchat</MenuItem>
          <MenuItem value="Word of Mouth">Word of Mouth</MenuItem>
          <MenuItem value="Flyer">Flyer</MenuItem>
          <MenuItem value="Other">Other</MenuItem>
        </TextField>
      </Grid>
      {profileData.year === 'Other' && (
        <Grid item xs={12} sm={6}>
          <TextField
            label="Please Specify Year In School"
            name="other_year"
            placeholder="e.g. Med Student, Incoming Freshman, etc."
            value={profileData.other_year}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
      )}
      {profileData.inbound_channel === 'Other' && (
        <Grid item xs={12} sm={6}>
          <TextField
            label="Please Specify How You Heard About Us"
            name="inbound_channel_other"
            placeholder="We'd love to know!"
            value={profileData.inbound_channel_other}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
      )}
    </Grid>
  </>
);

export default AdditionalInformation;
