import {
  Box,
  Button,
  Popover,
  Typography,
  useMediaQuery,
  TextField,
  Stack,
  Chip,
  useScrollTrigger,
  Select,
  MenuItem,
  FormControl,
  useTheme,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../config/firebase-config';
import { defaultFilters, Filters } from '../types/Filters';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

interface LeaseFilterProps {
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
}

function LeaseFilter({ filters, setFilters }: LeaseFilterProps) {
  const [user] = useAuthState(auth);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Popover anchors
  const [anchorElLocation, setAnchorElLocation] = useState<HTMLElement | null>(
    null
  );
  const [anchorElDates, setAnchorElDates] = useState<HTMLElement | null>(null);
  const [anchorElBeds, setAnchorElBeds] = useState<HTMLElement | null>(null);

  // useScrollTrigger to determine if we’re in shrunk mode
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleSelectAddress = async (address: string) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      // Update filters with the address string (you might also want to store latLng if needed)
      setFilters((prev) => ({
        ...prev,
        location: address,
        latitude: latLng.lat,
        longitude: latLng.lng,
      }));
      setAnchorElLocation(null);
    } catch (error) {
      console.error('Error selecting address:', error);
    }
  };

  // Handlers for opening popovers
  const handleOpenLocation = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLocation(event.currentTarget);
  };
  const handleOpenDates = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElDates(event.currentTarget);
  };
  const handleOpenBeds = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElBeds(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElLocation(null);
    setAnchorElDates(null);
    setAnchorElBeds(null);
  };

  // Helper function to format a date as dd/mm
  const formatDate = (dateStr: string) => {
    const date = new Date(dateStr);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    return `${month}/${day}`;
  };

  // For the date range display, show dd/mm - dd/mm
  const dateDisplay =
    filters.startDate && filters.endDate
      ? `${formatDate(filters.startDate)} - ${formatDate(filters.endDate)}`
      : 'Add dates';

  // For location, if too long, truncate with ellipsis
  const maxLocationLength = 10;
  const locationDisplay =
  filters.location && filters.location.length > maxLocationLength
      ? `${filters.location.substring(0, maxLocationLength)}...`
      : filters.location || 'Sort by location';

  // Container style
  const containerStyle = {
    position: trigger ? 'sticky' : 'static',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    transition: 'all 0.3s ease',
    py: 4,
  };

  // Main filter bar style
  const filterBarStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    border: `1px solid divider`,
    borderRadius: '40px',
    // boxShadow: '0 10px 15px rgba(0,0,0,0.08)',
    boxShadow: trigger
      ? `0 0 10px 5px rgb(156, 204, 252)`
      : '0 10px 15px rgba(0,0,0,0.08)',
    padding: trigger || isMobile ? '6px 8px' : '8px 4px',
    width: trigger || isMobile ? '95%' : '100%',
    maxWidth: trigger || isMobile ? '500px' : '700px',
    transition: 'all 0.3s ease',
  };

  return (
    <Box sx={containerStyle}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        maxWidth="800px"
        mx="auto"
      >
        {/* Main Filter Bar */}
        <Box sx={filterBarStyle}>
          {/* Location Filter */}
          <Button
            onClick={handleOpenLocation}
            sx={{
              borderRadius: '24px',
              textTransform: 'none',
              padding: trigger || isMobile ? '4px 12px' : '8px 16px',
              minWidth: trigger || isMobile ? '80px' : '150px',
              fontWeight: anchorElLocation ? 'bold' : 'normal',
              color: 'text.primary',
              '&:hover': { backgroundColor: 'rgba(0,0,0,0.04)' },
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box sx={{ textAlign: 'left' }}>
              <Typography variant="body2" fontWeight="medium">
                {trigger || isMobile ? '' : 'Where'}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2" color="text.secondary" noWrap>
                  {filters.location
                    ? locationDisplay
                    : trigger || isMobile
                    ? 'Where'
                    : 'Sort by location'}
                </Typography>
                {(trigger || isMobile) && <ExpandMoreIcon fontSize="small" />}
              </Box>
            </Box>
          </Button>

          <Box
            sx={{
              height: '24px',
              borderRight: '1px solid #ddd',
              margin: '0 8px',
            }}
          />

          {/* Date Range Filter */}
          <Button
            onClick={handleOpenDates}
            sx={{
              borderRadius: '24px',
              textTransform: 'none',
              padding: trigger || isMobile ? '4px 12px' : '8px 16px',
              minWidth: trigger || isMobile ? '100px' : '180px',
              fontWeight: anchorElDates ? 'bold' : 'normal',
              color: 'text.primary',
              '&:hover': { backgroundColor: 'rgba(0,0,0,0.04)' },
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box sx={{ textAlign: 'left' }}>
              <Typography variant="body2" fontWeight="medium">
                {trigger || isMobile ? '' : 'When'}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2" color="text.secondary" noWrap>
                  {filters.startDate && filters.endDate
                    ? dateDisplay
                    : trigger || isMobile
                    ? 'When'
                    : 'Add dates'}
                </Typography>
                {(trigger || isMobile) && <ExpandMoreIcon fontSize="small" />}
              </Box>
            </Box>
          </Button>

          <Box
            sx={{
              height: '24px',
              borderRight: '1px solid #ddd',
              margin: '0 8px',
            }}
          />

          {/* Beds Filter */}
          <Button
            onClick={handleOpenBeds}
            sx={{
              borderRadius: '24px',
              textTransform: 'none',
              padding: trigger || isMobile ? '4px 12px' : '8px 16px',
              minWidth: trigger || isMobile ? '80px' : '150px',
              fontWeight: anchorElBeds ? 'bold' : 'normal',
              color: 'text.primary',
              '&:hover': { backgroundColor: 'rgba(0,0,0,0.04)' },
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box sx={{ textAlign: 'left' }}>
              <Typography variant="body2" fontWeight="medium">
                {trigger || isMobile ? '' : 'Who'}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2" color="text.secondary" noWrap>
                  {filters.numBeds !== null
                    ? filters.numBeds === 1
                      ? '1 bed'
                      : `${filters.numBeds} beds`
                    : trigger || isMobile
                    ? 'Who'
                    : 'Add beds'}
                </Typography>
                {(trigger || isMobile) && <ExpandMoreIcon fontSize="small" />}
              </Box>
            </Box>
          </Button>

          {/* Search Button: icon-only */}
          {!isMobile && (
            <Button
              variant="contained"
              color="primary"
              sx={{
                borderRadius: '24px',
                minWidth: '40px',
                ml: 1,
                px: 1,
                py: trigger ? 0.5 : 0.5,
              }}
            >
              <SearchIcon />
            </Button>
          )}
        </Box>

        {/* Secondary Filter Options - only visible when not scrolled */}
        {!trigger && (
          <Stack
            direction="row"
            spacing={2}
            mt={2}
            justifyContent="center"
            flexWrap="wrap"
          >
            {user && (
              <>
                <Chip
                  label="My Listings"
                  clickable
                  color={filters.ownedListings ? 'primary' : 'default'}
                  onClick={() => {
                    setFilters((prev) => ({
                      ...prev,
                      ownedListings: !filters.ownedListings,
                      activeOffers: false,
                    }));
                  }}
                />
                <Chip
                  label="My Offers"
                  clickable
                  color={filters.activeOffers ? 'primary' : 'default'}
                  onClick={() => {
                    setFilters((prev) => ({
                      ...prev,
                      activeOffers: !filters.activeOffers,
                      ownedListings: false,
                    }));
                  }}
                />
              </>
            )}
            <Chip
              label="Clear Filters"
              variant="outlined"
              onClick={() => setFilters(defaultFilters)}
            />
          </Stack>
        )}
      </Box>

      {/* Popovers for Filters */}
      {/* Location Popover */}
      <Popover
        open={Boolean(anchorElLocation)}
        anchorEl={anchorElLocation}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            mt: 1,
            p: 2,
            width: 500,
            borderRadius: '16px',
            boxShadow: '0 6px 20px rgba(0,0,0,0.1)',
          },
        }}
      >
        <Typography variant="subtitle1" fontWeight="medium" gutterBottom>
          Enter an address
        </Typography>
        <Typography variant="subtitle2" fontWeight="medium" gutterBottom>
          Sort dubleases by distance
        </Typography>
        <PlacesAutocomplete
          value={filters.location || ''}
          onChange={(address: string) =>
            setFilters((prev) => ({ ...prev, location: address }))
          }
          onSelect={handleSelectAddress}
        >
          {({
            getInputProps,
            suggestions,
            loading,
            getSuggestionItemProps,
          }) => (
            <Box>
              <TextField
                {...getInputProps({
                  placeholder: 'Search for an address',
                })}
                variant="outlined"
                fullWidth
              />
              <Box
                sx={{
                  border: '1px solid #ddd',
                  borderRadius: '8px',
                  mt: 1,
                  maxHeight: '200px',
                  overflowY: 'auto',
                }}
              >
                {loading && (
                  <Typography variant="body2" sx={{ p: 1 }}>
                    Loading...
                  </Typography>
                )}
                {suggestions.map((suggestion, index) => {
                  const style = suggestion.active
                    ? {
                        backgroundColor: '#f0f0f0',
                        cursor: 'pointer',
                        padding: '8px',
                      }
                    : {
                        backgroundColor: '#fff',
                        cursor: 'pointer',
                        padding: '8px',
                      };
                  return (
                    <Box
                      // key={index}
                      {...getSuggestionItemProps(suggestion, { style })}
                    >
                      <Typography variant="body2">
                        {suggestion.description}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}
        </PlacesAutocomplete>
      </Popover>

      {/* Dates Popover */}
      <Popover
        open={Boolean(anchorElDates)}
        anchorEl={anchorElDates}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: {
            mt: 1,
            p: 2,
            width: 500,
            borderRadius: '16px',
            boxShadow: '0 6px 20px rgba(0,0,0,0.1)',
          },
        }}
      >
        <Typography variant="subtitle1" fontWeight="medium" gutterBottom>
          When will you be staying?
        </Typography>
        <Stack spacing={2} mt={2}>
          <TextField
            label="Start Date"
            type="date"
            value={filters.startDate}
            onChange={(e) =>
              setFilters((prev) => ({ ...prev, startDate: e.target.value }))
            }
            InputLabelProps={{ shrink: true }}
            fullWidth
            inputProps={{
              min: new Date().toISOString().split('T')[0],
            }}
          />
          <TextField
            label="End Date"
            type="date"
            value={filters.endDate}
            onChange={(e) =>
              setFilters((prev) => ({ ...prev, endDate: e.target.value }))
            }
            InputLabelProps={{ shrink: true }}
            fullWidth
            inputProps={{
              min: filters.startDate || new Date().toISOString().split('T')[0],
            }}
          />
        </Stack>
      </Popover>

      {/* Beds Popover */}
      <Popover
        open={Boolean(anchorElBeds)}
        anchorEl={anchorElBeds}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            mt: 1,
            p: 2,
            width: 500,
            borderRadius: '16px',
            boxShadow: '0 6px 20px rgba(0,0,0,0.1)',
          },
        }}
      >
        <Typography variant="subtitle1" fontWeight="medium" gutterBottom>
          How many beds do you need?
        </Typography>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <Select
            value={filters.numBeds}
            onChange={(e) =>
              setFilters((prev) => ({
                ...prev,
                numBeds: Number(e.target.value),
              }))
            }
            displayEmpty
            sx={{ borderRadius: '10px' }}
          >
            <MenuItem value={1}>1 bed</MenuItem>
            <MenuItem value={2}>2 beds</MenuItem>
            <MenuItem value={3}>3 beds</MenuItem>
            <MenuItem value={4}>4+ beds</MenuItem>
          </Select>
        </FormControl>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Roommate Options
          </Typography>
          <ToggleButtonGroup
            value={filters.numRoommates === 0 ? 'no' : 'yes'}
            exclusive
            onChange={(event, newValue) => {
              if (newValue !== null) {
                setFilters((prev) => ({
                  ...prev,
                  numRoommates: newValue === 'no' ? 0 : null,
                }));
              }
            }}
            aria-label="roommate toggle"
            sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <ToggleButton
              value="yes"
              aria-label="With roommates"
              sx={{ flex: 1, borderRadius: '16px 0 0 16px' }}
            >
              With Roommates
            </ToggleButton>
            <ToggleButton
              value="no"
              aria-label="No roommates"
              sx={{ flex: 1, borderRadius: '0 16px 16px 0' }}
            >
              No Roommates
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Popover>
    </Box>
  );
}

export default LeaseFilter;
