import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Avatar,
  TextField,
  Button,
  IconButton,
  Paper,
  Skeleton,
  useMediaQuery,
  Theme,
  Container,
  Badge,
  Divider,
  Tooltip,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { auth } from '../../config/firebase-config';
import { getUserProfile } from '../../api/userService';
import { brand, gray } from '../../theme/theme';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  deleteInteraction,
  getBid,
  getChatHistory,
  sendChatMessage,
} from '../../api/interactionService';
import io from 'socket.io-client';
import ReviewForm from './ReviewForm';
import ConfirmationDialog from '../common/confirmationDialog/ConfirmationDeleteDialog';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatChatTimestamp } from '../utils/formatTimestamps';
import { useActionMessage } from '../common/actionMessage/ActionMessage';
import { createPaymentRedirect } from '../../api/paymentService';
import AcceptOfferForm from './AcceptOffer';

const socket = io(process.env.REACT_APP_API_BASE_URL as string);

interface ChatProps {
  chatId: string;
  leaseId: string;
  isSubscriptionActive: boolean;
}

const Chat = ({ chatId, leaseId, isSubscriptionActive }: ChatProps) => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation();
  const { showMessage } = useActionMessage();
  const [chatData, setChatData] = useState({
    ownerId: '',
    bidderId: '',
    messages: [] as any[],
    otherUserProfile: null as any,
    currentOffer: null as any,
  });
  const [newMessage, setNewMessage] = useState('');
  const [isReviewing, setIsReviewing] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [paymentLoading, setPaymentLoading] = useState(false);

  const isXsScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  // Reference for scrolling to bottom of chat
  const chatEndRef = useRef<HTMLDivElement | null>(null);

  // Redirect to login if not authenticated
  if (!user) {
    navigate('/Login');
  }

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const fetchChatData = async () => {
    try {
      setLoading(true);
      const { chat, owner_id, bidder_id } = await getChatHistory(chatId);
      const profileIdToFetch = user?.uid === owner_id ? bidder_id : owner_id;
      const profileData = await getUserProfile(profileIdToFetch);
      const currentOffer =
        owner_id && bidder_id && leaseId
          ? await getBid(owner_id, bidder_id, leaseId)
          : null;

      setChatData({
        ownerId: owner_id,
        bidderId: bidder_id,
        messages: chat.map((msg: any) => ({
          ...msg,
          formattedTimestamp: formatChatTimestamp(msg.timestamp),
        })),
        otherUserProfile: profileData,
        currentOffer,
      });
    } catch (error) {
      console.error('Error loading chat data:', error);
    } finally {
      setLoading(false);
    }
  };

  // Initialize chat and socket connection
  useEffect(() => {
    fetchChatData();

    socket.emit('joinChat', chatId);

    socket.on('new_message', (newMsg) => {
      const formattedMessage = {
        ...newMsg,
        formattedTimestamp: formatChatTimestamp(newMsg.timestamp),
      };

      if (newMsg.sender_id !== auth.currentUser?.uid) {
        //we optimistically already show it
        setChatData((prevData) => ({
          ...prevData,
          messages: [...prevData.messages, formattedMessage],
        }));
      }

      if (newMsg.message?.includes('Offer is official!')) {
        fetchChatData();
      }

      // Small delay to ensure DOM updates before scrolling
      setTimeout(scrollToBottom, 100);
    });

    return () => {
      socket.off('new_message');
    };
  }, [chatId, user, leaseId]);

  // Scroll to bottom when messages change
  useEffect(() => {
    if (chatData.messages.length) {
      setTimeout(scrollToBottom, 100);
    }
  }, [chatData.messages]);

  // Check URL for special routes
  useEffect(() => {
    if (location.pathname.includes('/proceed-to-payment')) {
      setIsAccepting(true);
    } else if (location.pathname.includes('/make-official-offer')) {
      setIsReviewing(true);
    } else {
      setIsReviewing(false);
      setIsAccepting(false);
    }
  }, [location.pathname]);

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;
    // Create an optimistic message object
    const optimisticMessage = {
      sender_id: auth?.currentUser?.uid,
      message: newMessage,
      timestamp: new Date().toISOString(),
      formattedTimestamp: formatChatTimestamp(new Date().toISOString()),
    };

    // Optimistically update the chat state
    setChatData((prevData) => ({
      ...prevData,
      messages: [...prevData.messages, optimisticMessage],
    }));

    // Clear the input field immediately
    setNewMessage('');
    try {
      const user = auth.currentUser;
      if (user) {
        await sendChatMessage({
          chat_id: chatId,
          owner_id: chatData.ownerId,
          bidder_id: chatData.bidderId,
          lease_id: leaseId,
          sender_id: user.uid,
          message: newMessage,
          is_a_bid: false,
          timestamp: new Date().toISOString(),
        });
        setNewMessage('');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      //withdraw optimize chat
      setChatData((prevData) => ({
        ...prevData,
        messages: prevData.messages.filter(
          (msg) => msg.timestamp !== optimisticMessage.timestamp
        ),
      }));
    }
  };

  const handleDeleteInteraction = async () => {
    try {
      await deleteInteraction(
        chatId,
        chatData.ownerId,
        chatData.bidderId,
        leaseId
      );
      setDeleteDialogOpen(false);
      showMessage('Chat deleted, refresh to see changes', 'success');
    } catch (error) {
      console.error('Error deleting chat:', error);
    }
  };

  const callBackToChat = () => {
    scrollToBottom();
    setIsReviewing(false);
    setIsAccepting(false);
  };

  const handlePaymentRedirect = async () => {
    try {
      setPaymentLoading(true);
      const data = await createPaymentRedirect(chatData.currentOffer);
      window.location.href = data.url;
    } catch (error) {
      console.error('Error redirecting to payment:', error);
    } finally {
      setPaymentLoading(false);
    }
  };

  // Function for chat skeleton loading state
  const renderChatSkeleton = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: {
          xs: '55vh',
          sm: '80vh',
          md: '75vh',
        },
        backgroundColor: '#fff',
        borderRadius: { xs: 0, sm: 2 },
        overflow: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: 2,
          borderBottom: '1px solid #eaeaea',
          backgroundColor: brand[50],
        }}
      >
        <Skeleton variant="circular" width={40} height={40} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Skeleton variant="circular" width={50} height={50} />
          <Skeleton variant="text" width={80} sx={{ mt: 1 }} />
        </Box>
        <Skeleton variant="rectangular" width={80} height={36} />
      </Box>

      <Box sx={{ flexGrow: 1, p: 2, overflowY: 'hidden' }}>
        {[...Array(6)].map((_, i) => (
          <Box
            key={i}
            sx={{
              display: 'flex',
              justifyContent: i % 2 === 0 ? 'flex-start' : 'flex-end',
              mb: 2,
            }}
          >
            <Skeleton
              variant="rounded"
              width={i % 2 === 0 ? '60%' : '70%'}
              height={i % 3 === 0 ? 60 : 40}
            />
          </Box>
        ))}
      </Box>

      <Box sx={{ p: 2, borderTop: '1px solid #eaeaea' }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Skeleton variant="rounded" width="75%" height={50} />
          <Skeleton variant="rounded" width="25%" height={50} />
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      {loading ? (
        renderChatSkeleton()
      ) : (
        <>
          {chatData.messages.length > 0 ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: { xs: '80vh', sm: '75vh' },
                borderRadius: { xs: 0, sm: 2 },
                // boxShadow: { xs: 0, sm: 3 },
                backgroundColor: '#fff',
                overflow: 'hidden',
              }}
            >
              {/* Header with user profile */}
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  p: { xs: 1, sm: 2 },
                  backgroundColor: brand[50],
                  borderBottom: '1px solid rgba(0,0,0,0.1)',
                  zIndex: 10,
                }}
              >
                {/* Back button - always visible */}
                <IconButton
                  onClick={() => navigate(-1)}
                  aria-label="Back"
                  sx={{ color: gray[800] }}
                >
                  <ArrowBackIcon />
                </IconButton>

                {/* Profile section - centered */}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                    color="success"
                  >
                    <Avatar
                      src={chatData.otherUserProfile?.profile_picture_url}
                      alt={chatData.otherUserProfile?.first_name}
                      sx={{
                        width: { xs: 50, sm: 56 },
                        height: { xs: 50, sm: 56 },
                        border: '2px solid white',
                      }}
                    />
                  </Badge>
                  <Typography
                    variant="subtitle1"
                    sx={{ mt: 1, fontWeight: 500 }}
                  >
                    {chatData.otherUserProfile?.first_name}
                  </Typography>
                </Box>

                {/* Withdraw button - right side */}
                <Tooltip title="Withdraw from this conversation">
                  <IconButton
                    onClick={() => setDeleteDialogOpen(true)}
                    aria-label="Withdraw"
                    sx={{ color: gray[600] }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Tooltip>
              </Box>

              {/* Forms for making/accepting offers */}
              {isReviewing && (
                <ReviewForm
                  chatId={chatId}
                  ownerId={chatData.ownerId}
                  bidderId={chatData.bidderId}
                  leaseId={leaseId}
                  currentOffer={chatData.currentOffer}
                  callBackToChat={callBackToChat}
                />
              )}

              {isAccepting && (
                <AcceptOfferForm
                  currentOffer={chatData.currentOffer}
                  onProceedToPayment={handlePaymentRedirect}
                  loading={paymentLoading}
                  onCancel={callBackToChat}
                />
              )}

              {/* Chat content area */}
              {!isReviewing && !isAccepting && (
                <>
                  <Box
                    sx={{
                      flexGrow: 1,
                      p: { xs: 1, sm: 3 },
                      overflowY: 'auto',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1.5,
                      bgcolor: gray[50],
                    }}
                  >
                    {chatData.messages.map((msg, index) => {
                      const isCurrentUser =
                        msg.sender_id === auth.currentUser?.uid;
                      const isSystemMessage = msg.is_a_bid || msg.is_info_msg;
                      // Get next message (if any) for grouping logic
                      const nextMsg = chatData.messages[index + 1];

                      // Decide if we should show the timestamp
                      let showTimestamp = false;

                      if (!nextMsg || nextMsg.sender_id !== msg.sender_id) {
                        // If there's no next message or next message is from a different user
                        showTimestamp = true;
                      } else {
                        // Same user as nextMsg; check time difference
                        const currentTime = new Date(msg.timestamp).getTime();
                        const nextTime = new Date(nextMsg.timestamp).getTime();
                        if (nextTime - currentTime >= 120_000) {
                          // More than 1 minute difference
                          showTimestamp = true;
                        }
                      }

                      return (
                        <Box
                          key={index}
                          sx={{
                            display: 'flex',
                            justifyContent: isSystemMessage
                              ? 'center'
                              : isCurrentUser
                              ? 'flex-end'
                              : 'flex-start',
                            width: '100%',
                          }}
                        >
                          {/* System/info messages */}
                          {isSystemMessage ? (
                            <Paper
                              elevation={0}
                              sx={{
                                // backgroundColor: 'rgba(0,0,0,0.04)',
                                px: 2,
                                borderRadius: 99,
                                maxWidth: '80%',
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{
                                  color: msg.is_info_msg
                                    ? gray[700]
                                    : brand[600],
                                  fontWeight: msg.is_a_bid ? 500 : 400,
                                  textAlign: 'center',
                                }}
                              >
                                {msg.message}
                              </Typography>
                            </Paper>
                          ) : (
                            /* Normal chat messages */
                            <Box sx={{ maxWidth: '85%' }}>
                              <Paper
                                elevation={1}
                                sx={{
                                  p: 1.5,
                                  backgroundColor: isCurrentUser
                                    ? brand[500]
                                    : gray[100],
                                  color: isCurrentUser ? 'white' : 'black',
                                  borderRadius: 2,
                                  ml: isCurrentUser ? 0 : 0.5,
                                  mr: isCurrentUser ? 0.5 : 0,
                                }}
                              >
                                <Typography variant="body1">
                                  {msg.message}
                                </Typography>
                              </Paper>
                              {showTimestamp && (
                                <Typography
                                  variant="caption"
                                  sx={{
                                    display: 'block',
                                    mt: 0.5,
                                    ml: isCurrentUser ? 0 : 1.5,
                                    mr: isCurrentUser ? 1.5 : 0,
                                    textAlign: isCurrentUser ? 'right' : 'left',
                                    color: gray[600],
                                  }}
                                >
                                  {msg.formattedTimestamp}
                                </Typography>
                              )}
                            </Box>
                          )}
                        </Box>
                      );
                    })}
                    <div ref={chatEndRef} />
                  </Box>

                  {/* Message input area */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', sm: 'row' },
                      alignItems: 'center',
                      gap: 1.5,
                      px: { xs: 1, sm: 2 }, // Reduced padding especially on mobile
                      pt: { xs: 1, sm: 2 },
                      pb: { xs: 0, sm: 2 }, // Smaller bottom padding on mobile
                      mt: 'auto', // Push to bottom
                      borderTop: '1px solid rgba(0,0,0,0.1)',
                      backgroundColor: '#fff',
                    }}
                  >
                    {/* Action button - positioned differently based on screen size */}
                    <Button
                      variant="contained"
                      color={
                        user?.uid === chatData.ownerId ? 'success' : 'primary'
                      }
                      disabled={
                        user?.uid !== chatData.ownerId &&
                        !chatData.currentOffer?.is_live
                      }
                      fullWidth={isXsScreen}
                      sx={{
                        background:
                          user?.uid !== chatData.ownerId &&
                          !chatData.currentOffer?.is_live
                            ? 'inherit'
                            : `linear-gradient(90deg,${brand[500]},rgb(155, 216, 243))`,
                        color: '#fff',
                        boxShadow: 'none',
                        height: isXsScreen ? 40 : 52,
                        borderRadius: 2,
                        px: 3,
                        minWidth: { sm: 150 },
                        '&:hover': {
                          opacity: 0.9,
                        },
                      }}
                      onClick={() =>
                        isSubscriptionActive
                          ? navigate(`/posts/${leaseId}/payments`)
                          : navigate(
                              `/posts/${leaseId}/chat/${chatId}${
                                user?.uid === chatData.ownerId
                                  ? '/make-official-offer'
                                  : '/proceed-to-payment'
                              }`
                            )
                      }
                    >
                      {isSubscriptionActive
                        ? 'Check Progress'
                        : user?.uid === chatData.ownerId
                        ? 'Official Offer'
                        : 'Accept Offer'}
                    </Button>

                    {/* Message input field */}
                    <TextField
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                          e.preventDefault();
                          handleSendMessage();
                        }
                      }}
                      multiline
                      maxRows={3}
                      fullWidth
                      placeholder="Type your message..."
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            color="primary"
                            onClick={handleSendMessage}
                            disabled={!newMessage.trim()}
                            sx={{
                              transform: newMessage.trim()
                                ? 'scale(1.1)'
                                : 'scale(1)',
                              transition: 'transform 0.2s',
                            }}
                          >
                            <SendIcon />
                          </IconButton>
                        ),
                      }}
                      sx={{
                        order: { xs: 2, sm: 1 },
                        flexGrow: 1,
                        '& .MuiOutlinedInput-root': {
                          borderRadius: 4,
                          backgroundColor: gray[50],
                          '&:hover': {
                            backgroundColor: '#fff',
                          },
                          '&.Mui-focused': {
                            backgroundColor: '#fff',
                          },
                        },
                      }}
                    />
                  </Box>
                </>
              )}

              {/* Confirmation dialog for withdrawing/deleting chat */}
              <ConfirmationDialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                onConfirm={handleDeleteInteraction}
                title="Are you sure you want to withdraw? This chat and all progress will be deleted."
                confirmText="Delete"
                cancelText="Cancel"
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '50vh',
                p: 3,
                textAlign: 'center',
              }}
            >
              <Typography variant="h6" color="text.secondary" gutterBottom>
                No messages yet
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Make an offer to chat with the host!
              </Typography>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default Chat;
