import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  LinearProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import PhotoIcon from '@mui/icons-material/Photo';
import { Lease } from '../../../types/Lease';
import heic2any from 'heic2any';
import { useActionMessage } from '../../../common/actionMessage/ActionMessage';
import { brand } from '../../../../theme/theme';

interface ImageUploadProps {
  setImageFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setFormData: React.Dispatch<React.SetStateAction<Lease>>;
  formData: Lease;
}

interface FileWithPreview {
  file: File;
  preview: string;
  isLoading: boolean;
  progress: number;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  setImageFiles,
  setFormData,
  formData,
}) => {
  const [deletedImages, setDeletedImages] = useState<string[]>([]);
  const [filesWithPreviews, setFilesWithPreviews] = useState<FileWithPreview[]>(
    []
  );
  const { showMessage } = useActionMessage();
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Initialize filesWithPreviews from existing image_urls if available
  useEffect(() => {
    if (formData?.image_urls?.length && filesWithPreviews.length === 0) {
      const initialPreviews = formData.image_urls.map((url) => ({
        file: new File([], 'existing-image'), // Placeholder file for existing images
        preview: url,
        isLoading: false,
        progress: 100,
      }));
      setFilesWithPreviews(initialPreviews);
    }
  }, [formData?.image_urls]);

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (!files) return;

    const currentCount = filesWithPreviews.length;
    if (currentCount >= 7) {
      showMessage('You can only upload a maximum of 7 images', 'error');
      return;
    }

    const fileArray = Array.from(files);
    const maxFilesToProcess = Math.min(fileArray.length, 7 - currentCount);

    if (maxFilesToProcess < fileArray.length) {
      showMessage(
        `Only processing ${maxFilesToProcess} images to stay under the 7 image limit`,
        'warning'
      );
    }

    // Create placeholder entries first to show loading state
    const placeholders: FileWithPreview[] = [];
    for (let i = 0; i < maxFilesToProcess; i++) {
      placeholders.push({
        file: fileArray[i],
        preview: '',
        isLoading: true,
        progress: 0,
      });
    }

    setFilesWithPreviews((prev) => [...prev, ...placeholders]);

    // Process each file
    const newFilesWithPreviews: FileWithPreview[] = [];

    for (let i = 0; i < maxFilesToProcess; i++) {
      const file = fileArray[i];
      let processedFile = file;

      try {
        // Update progress for this specific file

        // Simulate progress updates for conversion/processing
        const progressInterval = setInterval(() => {
          const randomIncrement = Math.floor(Math.random() * 15) + 5; // Random increment between 5-20%
          setFilesWithPreviews((prev) => {
            const newPreviews = [...prev];
            const placeholderIndex = currentCount + i;
            if (newPreviews[placeholderIndex]) {
              const prevProgress = newPreviews[placeholderIndex].progress;
              newPreviews[placeholderIndex] = {
                ...newPreviews[placeholderIndex],
                progress: Math.min(prevProgress + randomIncrement, 90),
              };
            }
            return newPreviews;
          });
        }, 200);

        // Check if the file is a HEIC/HEIF image by extension or mime type
        if (
          /\.(heic|heif)$/i.test(file.name) ||
          file.type === 'image/heic' ||
          file.type === 'image/heif'
        ) {
          // Convert HEIC file to JPEG using heic2any
          const convertedBlob = await heic2any({
            blob: file,
            toType: 'image/jpeg',
          });

          // heic2any may return an array if multiple images are found, so we take the first one
          const blobToUse = Array.isArray(convertedBlob)
            ? convertedBlob[0]
            : convertedBlob;

          processedFile = new File(
            [blobToUse],
            file.name.replace(/\.(heic|heif)$/i, '.jpg'),
            { type: 'image/jpeg' }
          );
        }

        // Clear the progress interval
        clearInterval(progressInterval);

        // Create object URL for preview
        const url = URL.createObjectURL(processedFile);

        // Update the placeholder with the actual preview
        setFilesWithPreviews((prev) => {
          const newPreviews = [...prev];
          const placeholderIndex = currentCount + i;
          if (newPreviews[placeholderIndex]) {
            newPreviews[placeholderIndex] = {
              file: processedFile,
              preview: url,
              isLoading: false,
              progress: 100,
            };
          }
          return newPreviews;
        });

        newFilesWithPreviews.push({
          file: processedFile,
          preview: url,
          isLoading: false,
          progress: 100,
        });
      } catch (err) {
        console.error('Error processing file:', err);
        showMessage(`Failed to process ${file.name}`, 'error');

        // Remove the placeholder for failed file
        setFilesWithPreviews((prev) =>
          prev.filter((_, idx) => idx !== currentCount + i)
        );
      }
    }

    // Update form data and files
    const newFiles = newFilesWithPreviews.map((item) => item.file);
    const newPreviews = newFilesWithPreviews.map((item) => item.preview);

    setFormData((prev) => ({
      ...prev,
      image_urls: [...(prev.image_urls || []), ...newPreviews],
    }));
    setImageFiles((prev) => [...prev, ...newFiles]);

    // Reset file input
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleDeleteImage = (index: number) => {
    const imageUrl = filesWithPreviews[index].preview;

    // Check if this is an original image from the server
    if (formData?.image_urls?.includes(imageUrl)) {
      setDeletedImages((prev) => [...prev, imageUrl]);
    }

    // Remove from preview array
    setFilesWithPreviews((prev) => prev.filter((_, idx) => idx !== index));

    // Update form data and files
    setFormData((prev) => ({
      ...prev,
      image_urls: prev?.image_urls?.filter((_, idx) => idx !== index),
    }));
    setImageFiles((prev) => prev.filter((_, idx) => idx !== index));

    // Release object URL to prevent memory leaks
    if (imageUrl) {
      URL.revokeObjectURL(imageUrl);
    }
  };

  return (
    <Box>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ color: brand[400] }} gutterBottom>
          Upload Images
          <Typography
            variant="subtitle2"
            sx={{
              fontStyle: 'italic',
              color: 'grey',
            }}
            gutterBottom
          >
            (7 photos max)
          </Typography>
        </Typography>
        <Button
          component="label"
          variant="outlined"
          color="primary"
          fullWidth
          sx={{ mt: 2, mb: 2 }}
        >
          Choose Files
          <input
            ref={fileInputRef}
            type="file"
            multiple
            hidden
            onChange={handleImageChange}
            accept="image/*,video/*"
          />
        </Button>

        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
          {filesWithPreviews.map((item, index) => (
            <Box
              key={`${item.preview || index}-${index}`}
              sx={{
                position: 'relative',
                width: 100,
                height: 100,
                mb: 6, // Extra space for the controls below
                border: '1px solid #ddd',
                borderRadius: 1,
                overflow: 'hidden',
                backgroundColor: item.isLoading ? '#f5f5f5' : 'transparent',
              }}
            >
              {/* Delete button */}
              <IconButton
                onClick={() => handleDeleteImage(index)}
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  color: 'white',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  padding: '2px',
                  '&:hover': { backgroundColor: 'rgba(255,0,0,0.7)' },
                  zIndex: 10,
                }}
                size="small"
              >
                <CloseIcon fontSize="small" />
              </IconButton>

              {/* Loading state or actual image */}
              {item.isLoading ? (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    <PhotoIcon sx={{ fontSize: 40, color: 'grey.400' }} />
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={item.progress}
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      width: '100%',
                      height: 4,
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: brand[400],
                      },
                    }}
                  />
                </>
              ) : (
                <img
                  src={item.preview}
                  alt={`Preview ${index}`}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              )}
            </Box>
          ))}
        </Box>
      </Grid>
    </Box>
  );
};

export default ImageUpload;
