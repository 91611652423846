/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Grid,
  MenuItem,
  CircularProgress,
  Typography,
  InputAdornment,
  Divider,
} from '@mui/material';
import { AttachMoney } from '@mui/icons-material';
import { brand } from '../../theme/theme';
import { Lease } from '../types/Lease';
import { differenceInDays, isValid } from 'date-fns';

interface PlaceBidTabProps {
  lease: Lease;
  bidData: any | null;
  bidExists: boolean;
  loading: boolean;
  submitBid: (
    startDate: string,
    endDate: string,
    price: string,
    priceUnit: string,
    numBeds: number,
    additionalDetails: string
  ) => void;
}

const PlaceBidTab = ({
  lease,
  bidData,
  bidExists,
  loading,
  submitBid,
}: PlaceBidTabProps) => {
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split('T')[0]
  );
  const [endDate, setEndDate] = useState(lease.end_date.split('T')[0]);
  const [price, setPrice] = useState(lease.price);
  const [priceUnit, setPriceUnit] = useState(lease.price_unit);
  const [numBeds, setNumBeds] = useState(lease.num_rooms_for_lease || 1);
  const [selectBeds, setSelectBeds] = useState(lease.num_rooms_for_lease || 1);
  const [additionalDetails, setAdditionalDetails] = useState('');
  const STORAGE_KEY = 'make_offer_progress';
  // Populate fields with placed/saved/lease details
  useEffect(() => {
    const extractData = (data: any) => {
      setStartDate(data.start_date.split('T')[0]);
      setEndDate(data.end_date.split('T')[0]);
      setPrice(data.price);
      setPriceUnit(data.price_unit);
      setNumBeds(data.num_beds_for_bid || data.num_rooms_for_lease);
    };

    const savedData = localStorage.getItem(STORAGE_KEY);
    //if (savedData) {
    //  // No need to process further if data is from local storage
    //  const parsedData = JSON.parse(savedData);
    //  setStartDate(parsedData.startDate); // Use savedData's keys directly
    //  setEndDate(parsedData.endDate);
    //  setPrice(parsedData.price);
    //  setPriceUnit(parsedData.priceUnit);
    //  setNumBeds(parsedData.numBeds);
    //  setAdditionalDetails(parsedData.additionalDetails || '');
    //}

    if (bidData) {
      extractData(bidData);
    } else {
      extractData(lease);
      setStartDate(new Date().toISOString().split('T')[0]);
    }
  }, [bidData, lease]);

  useEffect(() => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const diffTime = end.getTime() - start.getTime();
      const diffDays = diffTime / (1000 * 60 * 60 * 24);
      // If the lease duration is less than 30 days, use "total", otherwise "month"
      setPriceUnit(diffDays < 40 ? 'total' : 'month');
    }
  }, [startDate, endDate]);

  // Save data as user in the case they navigate away to create profile
  useEffect(() => {
    const dataToSave = {
      startDate,
      endDate,
      price,
      priceUnit,
      numBeds,
      additionalDetails,
    };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(dataToSave));
  }, [startDate, endDate, price, priceUnit, numBeds, additionalDetails]);

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    submitBid(startDate, endDate, price, priceUnit, numBeds, additionalDetails);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        mt: 2,
      }}
    >
      {/* Conditionally render chat or place bid form */}
      <Typography variant="h6" sx={{ color: brand[500] }}>
        Make An Offer
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Box
        component="form"
        onSubmit={handleFormSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Start Date"
              type="date"
              required
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
              disabled={loading || bidExists}
              inputProps={{
                min: new Date().toISOString().split('T')[0],
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="End Date"
              type="date"
              required
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
              disabled={loading || bidExists}
              inputProps={{
                min: new Date().toISOString().split('T')[0],
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6} sm={3}>
            <TextField
              label="Beds"
              type="number"
              required
              value={numBeds || ''}
              disabled={loading || bidExists}
              onChange={(e) => setNumBeds(Number(e.target.value))}
              fullWidth
              InputProps={{ inputProps: { min: 1 } }}
              select
            >
              {Array.from({ length: selectBeds }, (_, index) => index + 1).map(
                (value) => (
                  <MenuItem key={value} value={value} sx={{ m: '5px ' }}>
                    {value}
                  </MenuItem>
                )
              )}
            </TextField>
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              label="Price"
              type="number"
              required
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              fullWidth
              disabled={loading || bidExists}
              InputProps={{
                inputProps: { min: 0 },
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoney />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              label="Unit Price"
              required
              select
              value={priceUnit || ''}
              onChange={(e) => setPriceUnit(e.target.value)}
              fullWidth
              disabled={true}
            >
              <MenuItem value="total">Total</MenuItem>
              <MenuItem value="month">Per month</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              label="Utilities Included"
              required
              value={lease.utilities_included_in_price ? 'Yes' : 'No'}
              fullWidth
              disabled={true}
              InputProps={{ inputProps: { min: 1 } }}
            />
          </Grid>
        </Grid>

        <TextField
          label="Additional Details"
          multiline
          rows={4}
          value={additionalDetails}
          onChange={(e) => setAdditionalDetails(e.target.value)}
          fullWidth
          disabled={loading || bidExists}
          placeholder="Share any preferences, questions, or special requests you have for this sublease (e.g., parking, furniture, utilities, appliances, pets, safety, location)."
        />

        <Button
          type="submit"
          variant="contained"
          color={!bidExists ? 'primary' : 'secondary'}
          fullWidth
          disabled={loading || bidExists}
        >
          {loading ? <CircularProgress size={24} /> : 'Submit Offer'}
        </Button>
      </Box>
    </Box>
  );
};

export default PlaceBidTab;
