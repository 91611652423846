// React
import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, IconButton, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// Firebase
import { auth } from '../../../config/firebase-config';
import { useAuthState } from 'react-firebase-hooks/auth';
// Components
import ImageUpload from './sections/ImageUpload';
import FormInputs from './sections/FormInputs';
import SubmitButton from './sections/SubmitButton';
import ConfirmationDialog from '../../common/confirmationDialog/ConfirmationDeleteDialog';
// Theme
import { brand } from '../../../theme/theme';
import { useActionMessage } from '../../common/actionMessage/ActionMessage';
import { deleteLease, saveLease } from '../../../api/leaseService';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkProfileCompletion } from '../../../api/userService';
import HomeIcon from '@mui/icons-material/Home';

interface PostFormProps {
  onFormChange: () => void;
}

// Interface for Lease
//TODO Error Handling
const PostForm: React.FC<PostFormProps> = ({ onFormChange }) => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation(); //we pass in post info through router state aka location
  const post = location.state?.post;
  const { showMessage } = useActionMessage();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [initialFormData] = useState<any>({
    id: '',
    owner_id: user?.uid || '',
    email: user?.email || '',
    address: '',
    latitude: '',
    longitude: '',
    start_date: '',
    end_date: '',
    price: '',
    price_unit: 'month',
    utilities_included_in_price: '',
    num_rooms_for_lease: '',
    num_property_bedrooms: '',
    are_roommates_aware: '',
    num_roommates_present_during_dublease: '',
    housing_gender: '',
    num_bathrooms: '',
    description: '',
    reason_for_dubleasing: '',
    reason_for_dubleasing_other: '',
    term_of_service: false,
    image_urls: [],
    delete_image_urls: [],
    updatedAt: new Date(),
    amenities: [],
    verified: false,
  });
  const [formData, setFormData] = useState<any>(initialFormData);
  const [profileMessageShown, setProfileMessageShown] = useState(false);
  const STORAGE_KEY = 'post_form_progress';

  useEffect(() => {
    const checkProfile = async () => {
      if (!user || profileMessageShown) return; // Ensure the user is signed in and the message hasn't been shown yet
      const isProfileComplete = await checkProfileCompletion();
      if (!isProfileComplete) {
        showMessage('Verification is now mandatory.', 'info');
        showMessage(
          'You must complete your profile to list your place.',
          'error'
        );
        setProfileMessageShown(true); // Set flag to prevent repeated messages
      }
    };

    checkProfile();
  }, [user, profileMessageShown, showMessage]);
  // Set the form data to the post data if it exists
  useEffect(() => {
    const savedData = localStorage.getItem(STORAGE_KEY);
    if (post) {
      setFormData({
        ...post,
        start_date: post.start_date.split('T')[0],
        end_date: post.end_date.split('T')[0],
      });
    } else if (savedData) {
      const parsedData = JSON.parse(savedData);
      setFormData({ ...parsedData, image_urls: [] });
      setImageFiles([]);
      showMessage('Please upload images again', 'info');
    } else {
      setFormData({ ...initialFormData });
    }
  }, [post, user]);

  // Set the price unit based on the lease duration
  useEffect(() => {
    if (formData.start_date && formData.end_date) {
      const start = new Date(formData.start_date);
      const end = new Date(formData.end_date);
      const diffTime = end.getTime() - start.getTime();
      const diffDays = diffTime / (1000 * 60 * 60 * 24);
      // If the lease duration is less than 30 days, use "total", otherwise "month"
      setFormData((prev: any) => ({
        ...prev,
        price_unit: diffDays < 40 ? 'total' : 'month', // 40 days >= warrants subscription
      }));
    }
  }, [formData.start_date, formData.end_date]);

  const saveProgress = () => {
    const savedData = {
      ...formData,
      // imageFiles: imageFiles,
    };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(savedData));
  };

  const clearProgress = () => {
    localStorage.removeItem(STORAGE_KEY);
  };

  // Handle form submission
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    // Trigger native validation UI
    if (!form.reportValidity()) {
      return;
    } else if (formData.start_date > formData.end_date) {
      showMessage('End Date Has To Be After Start Date', 'error');
      return;
    }

    // }
    //Check profile completion
    const isProfileComplete = await checkProfileCompletion();
    if (!isProfileComplete) {
      saveProgress();
      showMessage('You must create a profile to List Your Place', 'error');
      navigate('/Profile');
      return;
    }
    //Submit
    try {
      setIsSubmitting(true);
      const result = await saveLease(formData, imageFiles);
      showMessage(result.message, 'success');
      onFormChange();
      clearProgress();
      onClose();
    } catch (error: any) {
      showMessage(error.message, 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Delete the post and close the dialog
  const handleDeleteConfirm = async () => {
    if (post && user && user?.uid === post.owner_id) {
      try {
        const result = await deleteLease(post.id || '');
        showMessage(result.message, 'success');
        onFormChange();
        onClose();
        // TODO reload the page
      } catch (error) {
        showMessage('Error deleting post. Please try again.', 'error');
      }
    }
  };

  // Handle form input changes
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    setFormData((prev: any) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const onClose = () => navigate('/');

  return (
    <Modal
      open
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          borderRadius: '10px',
          border: '1px solid',
          borderColor: 'divider',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '100%', sm: '90%', md: '70%', lg: '50%' }, // Responsive width
          maxHeight: { xs: '100vh', sm: '93vh' },
          overflowY: 'auto', // Allow vertical scrolling
          bgcolor: 'background.paper',
          boxShadow: `0 0 100px 10px rgb(156, 204, 252)`, // Adds a blue glow
          p: { xs: 2, sm: 4 },
          pt: { xs: 8 },
          pb: { xs: 7 },
          outline: 0, // Remove default focus outline
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: -2,
            mb: '2px',
          }}
        >
          <IconButton sx={{ zIndex: 1 }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography
          id="modal-title"
          variant="h5"
          sx={{
            display: 'flex',
            gap: 1.2,
            color: brand[400],
            alignItems: 'center',
          }}
          component="h2"
        >
          <HomeIcon sx={{ fontSize: 32 }} />
          List Your Place
        </Typography>
        <Divider variant="fullWidth" sx={{ mt: 2 }} />
        <Box component="form" noValidate onSubmit={onSubmit} sx={{ mt: 1 }}>
          {/* Form Inputs */}
          <FormInputs
            formData={formData}
            onChange={onChange}
            setFormData={setFormData}
          />
          {/* Image Upload */}
          <ImageUpload
            setImageFiles={setImageFiles}
            formData={formData}
            setFormData={setFormData}
          />
          {/* Clear & Submit */}
          <SubmitButton
            isSubmitting={isSubmitting}
            onReset={() => setFormData({ ...initialFormData, imageUrls: [] })}
            onDelete={() => setDeleteDialogOpen(true)}
            post={post}
          />

          {/* Delete Confirmation Dialog */}
          <ConfirmationDialog
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
            onConfirm={handleDeleteConfirm}
            title="Are you sure you want to delete this post? Deleting the post will also delete the bids associated with it."
            confirmText="Delete"
            cancelText="Cancel"
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default PostForm;
