import React, { useEffect, useState } from 'react';
import { Box, Grid, Pagination, Skeleton, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../config/firebase-config';
import { Lease } from '../types/Lease';
import { getLeases } from '../../api/leaseService';
import { getOwnerLeases } from '../../api/interactionService';
import LeaseFilter from './Filters';
import LeaseCard from './LeaseCard';
import { defaultFilters, Filters } from '../types/Filters';

interface LeaseDisplayProps {
  refreshLeases: boolean;
}

const LeaseDisplay: React.FC<LeaseDisplayProps> = ({ refreshLeases }) => {
  const [user] = useAuthState(auth);
  const location = useLocation();
  const [leases, setLeases] = useState<Lease[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Filter States
  const [filters, setFilters] = useState<Filters>(defaultFilters);

  useEffect(() => {
    const loadLeases = async () => {
      const minimumLoadingTime = 500;
      const startTime = Date.now();
      setLoading(true);
      try {
        let posts: Lease[] = [];
        let totalCount = 0;

        if (filters?.ownedListings) {
          const ownerLeases = await getOwnerLeases(user?.uid || '');
          posts = ownerLeases || [];
          totalCount = ownerLeases.length;
        } else {
          const otherPosts = await getLeases(page, filters);
          posts = otherPosts.posts || [];
          totalCount = otherPosts.totalCount || 0;
        }

        setTotalPages(Math.ceil(totalCount / 12)); // 12 items per page
        setLeases(posts);
      } catch (error) {
        console.error('Error loading leases:', error);
      } finally {
        const elapsedTime = Date.now() - startTime;
        const remainingTime = minimumLoadingTime - elapsedTime;
        setTimeout(() => setLoading(false), Math.max(remainingTime, 0));
      }
    };

    loadLeases();
  }, [page, user, filters, refreshLeases]);

  useEffect(() => {
    if (location.state?.resetPage) {
      setPage(1);
      // Optionally, clear the reset flag so it doesn't trigger again on re-render:
      // This can be done via navigate('/', { replace: true, state: {} })
    }
  }, [location]);

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  // Skeleton loading placeholders
  const renderSkeletons = () => (
    <Grid container spacing={1} justifyContent="flex-start">
      {[...Array(12)].map((_, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2.3} key={index}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            height={400}
            sx={{ borderRadius: '8px', width: '100%' }}
          />
        </Grid>
      ))}
    </Grid>
  );

  // Framer Motion settings
  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: { staggerChildren: 0.2, delayChildren: 0.3 },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 10, scale: 0.95 },
    show: { opacity: 1, y: 0, scale: 1 },
    exit: { opacity: 0, y: -10 },
  };

  return (
    <>
      <LeaseFilter filters={filters} setFilters={setFilters} />
      <Box sx={{ minHeight: '100vh', px: { xs: 2, sm: 8 } }}>
        <Box
          sx={{
            mx: 'auto',
            maxWidth: '2000px',
          }}
        >
          {loading ? (
            renderSkeletons()
          ) : leases.length > 0 ? (
            <motion.div
              variants={containerVariants}
              initial="hidden"
              animate="show"
              exit="hidden"
            >
              <Grid container spacing={1} justifyContent="flex-start">
                {leases.map((lease) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={2.3}
                    key={lease.id}
                  >
                    <motion.div
                      variants={cardVariants}
                      transition={{ duration: 0.6, ease: 'easeInOut' }}
                    >
                      <LeaseCard
                        lease={lease}
                        onClick={() => navigate(`/posts/${lease.id}`)}
                      />
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </motion.div>
          ) : (
            <Box sx={{ textAlign: 'center', py: { xs: 2, sm: 20 } }}>
              <Typography variant="h4" color="textSecondary" gutterBottom>
                No leases available yet.
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Be the first to post a lease and help others find their next
                home!
              </Typography>
            </Box>
          )}

          {totalPages > 1 && (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 8 }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
          )}
        </Box>

        <Outlet />
      </Box>
    </>
  );
};

export default LeaseDisplay;
