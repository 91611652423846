import { Filters } from '../components/types/Filters';
import { auth } from '../config/firebase-config';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Save a new lease or update an existing one
export const saveLease = async (formData: any, imageFiles: File[]): Promise<any> => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('User not authenticated');

    const idToken = await user.getIdToken();

    const form = new FormData();
    form.append('postData', JSON.stringify(formData));
    imageFiles.forEach((file) => form.append('images', file));

    const response = await fetch(`${API_BASE_URL}/api/posts/save`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      body: form,
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message || `Failed to save lease: ${response.statusText}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error saving lease:', error);
    throw error;
  }
};

// Delete a lease
export const deleteLease = async (postId: string): Promise<any> => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('User not authenticated');

    const idToken = await user.getIdToken();

    const response = await fetch(`${API_BASE_URL}/api/posts/delete/${postId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to delete lease: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error deleting lease:', error);
    throw error;
  }
};

// Fetch lease details for a specific lease (used: Inbox -> Incoming Offers -> LeaseDetailsModal.tsx)
export const getLease = async (leaseId: string): Promise<any> => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/posts/getLease?id=${leaseId}`, {
        method: 'GET',
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch lease details: ${response.statusText}`);
    }

    const data = await response.json();
    return data.lease || {};
  } catch (error) {
    console.error('Error fetching lease details:', error);
    throw error;
  }
};

// Will fetch all leases from the backend to display (used: leaseDisplay)
export const getLeases = async (
  page: number, 
  filters: Filters,
): Promise<any> => {
    try {
      const queryObject: Record<string, string> = {
        page: page.toString(),
        startDate: filters.startDate ?? '',
        endDate: filters.endDate ?? '',
        location: filters.location ?? '',
        lat: filters.latitude !== null ? filters.latitude.toString() : '',
        lng: filters.longitude !== null ? filters.longitude.toString() : '',
        numBeds:
          filters.numBeds !== null && filters.numBeds !== undefined
            ? filters.numBeds.toString()
            : '',
        numRoommates:
          filters.numRoommates !== null && filters.numRoommates !== undefined
            ? filters.numRoommates.toString()
            : '',
        sortPrice: filters.sortPrice ?? '',
        activeOffers: filters.activeOffers ? filters.activeOffers.toString() : '',
        available: filters.available ?? '',
        owner: auth.currentUser?.uid ?? '',
        ownedListings: filters.ownedListings ? filters.ownedListings.toString() : '',
      };
      const queryParams = new URLSearchParams(queryObject);
      const response = await fetch(`${API_BASE_URL}/api/posts/getLeases?${queryParams}`, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch lease details: ${response.statusText}`);
      }

      const data = await response.json();
      return data || [];
    } catch (error) {
      console.error('Error fetching leases:', error);
      throw new Error('Unable to fetch leases');
    }
  };