// src/App.tsx
import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Navbar from './components/layout/navbar/Navbar';
import Footer from './components/layout/footer/Footer';
import AboutUs from './components/layout/footer/sections/AboutUs';
import Support from './components/layout/footer/sections/Support';
import LeaseDisplay from './components/dubleases/LeaseDisplay';
import { ActionMessageProvider } from './components/common/actionMessage/ActionMessage';
import { loadGoogleMapsApi } from './components/utils/LoadGoogleMapsAPI';
import LeaseDetailsModal from './components/dubleases/LeaseDetailsModal';
import PostForm from './components/dubleases/form/PostForm';
import Profile from './components/profile/Profile';
import TermsAndConditions from './components/layout/footer/sections/TermsAndConditions';
import PrivacyPolicy from './components/layout/footer/sections/PrivacyPolicy';
import Login from './components/auth/Login';
import Inbox from './components/inbox/Inbox';
import TermsOfService from './components/layout/footer/sections/TermsOfService';
import SplashScreen from './components/utils/SplashScreen';
import Banner from './components/utils/Banner';
import { Divider, useMediaQuery } from '@mui/material';
import theme from './theme/theme';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Analytics } from '@vercel/analytics/react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './config/firebase-config';
import ProtectedRoute from './components/utils/ProtectedRoute';

const App: React.FC = () => {
  const [user] = useAuthState(auth);
  const [splashVisible, setSplashVisible] = useState(true);
  const [bannerHeight, setBannerHeight] = useState(0);
  const [refreshLeases, setRefreshLeases] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile screens
  const isAuthenticated = !!user;
  // TODO pre-fetch data (cache) for the LeaseDisplay component
  useEffect(() => {
    const timer = setTimeout(() => {
      setSplashVisible(false);
    }, 3000); // 2 seconds delay

    // Load Google Maps API
    loadGoogleMapsApi();

    return () => clearTimeout(timer);
  }, []);

  const handleBannerVisibility = (visible: boolean) => {
    setBannerHeight(visible && !isMobile ? 70 : 0);
  };

  const handleRefresh = () => {
    setRefreshLeases((prev) => !prev);
  };

  return (
    <>
      <SpeedInsights /> {/*Vercel speed insights*/}
      <Analytics /> {/*Vercel web analytics*/}
      <Router>
        <ActionMessageProvider>
          {splashVisible ? (
            <SplashScreen onComplete={() => setSplashVisible(false)} />
          ) : (
            <>
              <Banner onVisibilityChange={handleBannerVisibility} />
              <Navbar bannerHeight={bannerHeight} />
              <Divider sx={{ p: 2 }} />
              <Routes>
                <Route
                  path="/"
                  element={<LeaseDisplay refreshLeases={refreshLeases} />}
                >
                  <Route path="/login" element={<Login />} />
                  <Route
                    path="/Profile"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Profile />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/Inbox"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Inbox />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/ListYourPlace"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <PostForm onFormChange={handleRefresh} />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="posts/:leaseId/"
                    element={
                      //<ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LeaseDetailsModal onFormChange={handleRefresh} />
                      //</ProtectedRoute>
                    }
                  />
                  <Route
                    path="posts/:leaseId/payments"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <LeaseDetailsModal onFormChange={handleRefresh} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="posts/:leaseId/incoming-offers"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <LeaseDetailsModal onFormChange={handleRefresh} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/posts/:leaseId/chat/:chatId"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <LeaseDetailsModal onFormChange={handleRefresh} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/posts/:leaseId/chat/:chatId/proceed-to-payment"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <LeaseDetailsModal onFormChange={handleRefresh} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/posts/:leaseId/chat/:chatId/make-official-offer"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <LeaseDetailsModal onFormChange={handleRefresh} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/Terms&Conditions"
                    element={<TermsAndConditions />}
                  />
                  <Route path="/TermsOfService" element={<TermsOfService />} />
                  <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                </Route>
                <Route path="/Support" element={<Support />} />
                <Route path="/AboutUs" element={<AboutUs />} />
              </Routes>
              <Footer />
            </>
          )}
        </ActionMessageProvider>
      </Router>
    </>
  );
};

export default App;
