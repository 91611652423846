import React from 'react';
import {
  TextField,
  Grid,
  MenuItem,
  InputAdornment,
  Typography,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { auth } from '../../../../config/firebase-config';
import { AttachMoney, WifiPassword } from '@mui/icons-material';
import PoolIcon from '@mui/icons-material/Pool';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import LocalLaundryServiceIcon from '@mui/icons-material/LocalLaundryService';
import AirIcon from '@mui/icons-material/Air';
import WeekendIcon from '@mui/icons-material/Weekend';
import KitchenIcon from '@mui/icons-material/Kitchen';
import PetsIcon from '@mui/icons-material/Pets';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { brand, gray } from '../../../../theme/theme';

interface FormInputsProps {
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormInputs: React.FC<FormInputsProps> = ({
  formData,
  onChange,
  setFormData,
}) => {
  const amenitiesList = [
    { label: 'WiFi', icon: <WifiPassword /> },
    // { label: 'Utilities', icon: <WifiPassword /> },
    { label: 'Gym', icon: <FitnessCenterIcon /> },
    { label: 'Pool', icon: <PoolIcon /> },
    { label: 'Furnished', icon: <WeekendIcon /> },
    { label: 'AC', icon: <AirIcon /> },
    { label: 'Laundry', icon: <LocalLaundryServiceIcon /> },
    { label: 'Dishwasher', icon: <KitchenIcon /> },
    { label: 'Pet Friendly', icon: <PetsIcon /> },
    { label: 'Parking', icon: <LocalParkingIcon /> },
  ];
  // Function to handle selected address from dropdown
  const handleSelect = async (address: string) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      console.log('Success', latLng); // Possibly integrate latLng into your form state if needed
      setFormData((prevState: any) => ({
        ...prevState,
        address: address,
        latitude: latLng.lat,
        longitude: latLng.lng,
      }));
    } catch (error) {
      console.error('Error', error);
    }
  };
  return (
    <Grid container spacing={1}>
      {/* Section 1 - Listing Details*/}
      <Box mt={2}>
        <Typography variant="h6" sx={{ color: brand[400] }} gutterBottom>
          Listing Details
        </Typography>
        <Grid container spacing={1}>
          {/* Email */}
          <Grid item xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={
                auth.currentUser?.email ||
                'You must sign in/sign up to list your place'
              }
              disabled={true}
            />
          </Grid>
          {/* Address with Autocomplete */}
          <Grid item xs={12}>
            <PlacesAutocomplete
              value={formData.address}
              onChange={(address: string) =>
                setFormData((prevState: any) => ({ ...prevState, address }))
              }
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <TextField
                    {...getInputProps({
                      label: 'Address',
                      className: 'location-search-input',
                      fullWidth: true,
                      margin: 'normal',
                      required: true,
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </Grid>
          {/* Start Date */}
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="start_date"
              label="Start Date"
              name="start_date"
              type="date"
              value={formData.start_date}
              onChange={onChange}
              InputLabelProps={{
                shrink: true, // Always shrink the label
              }}
              inputProps={{
                min: new Date().toISOString().split('T')[0], // Restrict dates to today or later
              }}
            />
          </Grid>
          {/* End Date */}
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="end_date"
              label="End Date"
              name="end_date"
              type="date"
              value={formData.end_date}
              onChange={onChange}
              InputLabelProps={{
                shrink: true, // Always shrink the label
              }}
              inputProps={{
                min: new Date().toISOString().split('T')[0], // Restrict dates to today or later
              }}
            />
          </Grid>
          {/* Price */}
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="price"
              label="Price"
              name="price"
              type="number"
              // inputMode="numeric"
              value={formData.price}
              onChange={onChange}
              InputProps={{
                inputProps: { min: 0 },
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoney />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="The price should reflect the total of all rooms for dublease.">
                      <IconButton
                        edge="end"
                        size="small"
                        sx={{ color: 'primary.main', marginRight: -1 }} // Adjust the position slightly
                      >
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {/* Price Unit */}
          <Grid item xs={12} sm={3}>
            <TextField
              margin="normal"
              select
              required
              fullWidth
              id="price_unit"
              label="Price Unit"
              name="price_unit"
              value={formData.price_unit}
              onChange={onChange}
              disabled={true}
            >
              <MenuItem value="month">Per month</MenuItem>
              <MenuItem value="total">Total</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              margin="normal"
              select
              required
              fullWidth
              id="utilities_included_in_price"
              label="Utilities Included"
              name="utilities_included_in_price"
              value={formData.utilities_included_in_price}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  utilities_included_in_price: e.target.value === 'true', // Convert to boolean
                })
              }
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </TextField>
          </Grid>
          {/* Number of Rooms for Lease */}
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="num_rooms_for_lease"
              label="Available Beds for Sublease"
              name="num_rooms_for_lease"
              type="number"
              inputMode="numeric"
              value={formData.num_rooms_for_lease}
              onChange={onChange}
              sx={{ inputProps: { min: 1 } }}
              select
            >
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="6">6</MenuItem>
            </TextField>
          </Grid>
          {/* Total Bedrooms */}
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              select
              required
              fullWidth
              id="num_property_bedrooms"
              label="Total Beds in the Unit"
              name="num_property_bedrooms"
              type="number"
              value={formData.num_property_bedrooms}
              onChange={onChange}
            >
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="6">6</MenuItem>
            </TextField>
          </Grid>
          {/* Bathrooms */}
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="num_bathrooms"
              label="Bathrooms"
              name="num_bathrooms"
              type="number"
              inputMode="numeric"
              // InputProps={{ inputProps: { min: 0 } }}
              select
              value={formData.num_bathrooms}
              onChange={onChange}
            >
              {' '}
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="1.5">1.5</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="2.5">2.5</MenuItem>
              <MenuItem value="3">3+</MenuItem>
            </TextField>
          </Grid>
          {/* Roomates Are Aware */}
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="are_roommates_aware"
              label="Are Roommates Aware?"
              name="are_roommates_aware"
              select
              value={formData.are_roommates_aware}
              onChange={onChange}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="N/A">N/A</MenuItem>
            </TextField>
          </Grid>
          {/* Roomates Will Be Present */}
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="num_roommates_present_during_dublease"
              label="Roommates Present During Sublease"
              name="num_roommates_present_during_dublease"
              type="number"
              select
              value={formData.num_roommates_present_during_dublease}
              onChange={onChange}
            >
              {' '}
              <MenuItem value="0">0</MenuItem>
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
              <MenuItem value="5">5</MenuItem>
            </TextField>
          </Grid>
          {/* Gender Composition */}
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="housing_gender"
              label="Gender Composition of Sublease"
              name="housing_gender"
              select
              value={formData.housing_gender}
              onChange={onChange}
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Mixed">Mixed</MenuItem>
              <MenuItem value="N/A - Single Bed Unit">
                N/A [Single Bed Unit]
              </MenuItem>
              <MenuItem value="N/A - Other">N/A [Other]</MenuItem>
            </TextField>
          </Grid>
          {/* Why Subleasing */}
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="reason_for_dubleasing"
              label="Reason for Subleasing your Place?"
              name="reason_for_dubleasing"
              select
              value={formData.reason_for_dubleasing}
              onChange={onChange}
            >
              <MenuItem value="Athletics">Athletics</MenuItem>
              <MenuItem value="Graduating Early">
                Graduatating Early/Late
              </MenuItem>
              <MenuItem value="Internship">Internship/Co-Ops/Research</MenuItem>
              <MenuItem value="Study Abroad">Study Abroad</MenuItem>
              <MenuItem value="Away for the Summer">
                Away for the Summer
              </MenuItem>
              <MenuItem value="Transferring">Transferring</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </TextField>
          </Grid>
          {/* Other Reason */}
          <Grid item xs={12} sm={6}>
            {formData.reason_for_dubleasing === 'Other' && (
              <TextField
                margin="normal"
                required
                fullWidth
                type="text"
                label="Please Specify Reason for Dubleasing"
                name="reason_for_dubleasing_other"
                placeholder="We'd love to know!"
                value={formData.reason_for_dubleasing_other}
                onChange={onChange}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      {/* Section 2 - Ammenities */}
      <Box>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            py={1}
            sx={{ color: brand[400] }}
            gutterBottom
          >
            Amenitites
            <Typography
              variant="subtitle2"
              sx={{
                fontStyle: 'italic',
                color: 'grey',
              }}
              gutterBottom
            >
              Add any amenities that are included for the dublease
            </Typography>
          </Typography>
          <Grid container spacing={2}>
            {amenitiesList.map((amenity) => (
              <Grid item xs={4} sm={2} key={amenity.label}>
                <Box
                  onClick={() => {
                    setFormData((prev: any) => {
                      const amenities = prev?.amenities || []; // Safely access amenities
                      const isSelected = amenities.includes(amenity.label);
                      return {
                        ...prev,
                        amenities: isSelected
                          ? amenities.filter(
                              (item: string) => item !== amenity.label
                            )
                          : [...amenities, amenity.label],
                      };
                    });
                  }}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: 1,
                    borderColor: 'transparent',
                    borderRadius: 5,
                    padding: 1,
                    cursor: 'pointer',
                    backgroundColor: formData.amenities?.includes(amenity.label)
                      ? brand[300]
                      : gray[100],
                    color: formData.amenities?.includes(amenity.label)
                      ? 'white'
                      : 'black',
                    '&:hover': {
                      backgroundColor: formData.amenities?.includes(
                        amenity.label
                      )
                        ? brand[300]
                        : brand[300],
                    },
                  }}
                >
                  {amenity.icon}
                  <Typography variant="caption">{amenity.label}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
      {/* Description */}
      <Box>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            pt={2}
            sx={{ color: brand[400] }}
            gutterBottom
          >
            Description
            <Typography
              variant="subtitle2"
              sx={{
                fontStyle: 'italic',
                color: 'grey',
              }}
              gutterBottom
            >
              Provide any other relevant information about your property, lease
              policies, furnishing details, utilities coverage, or explanations
              for previous inputs
            </Typography>
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="description"
            label="Description"
            name="description"
            type="text"
            multiline
            rows={4}
            value={formData.description}
            onChange={onChange}
          />
        </Grid>
      </Box>
    </Grid>
  );
};

export default FormInputs;
