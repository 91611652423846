import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { Badge, MenuItem, Typography, Divider } from '@mui/material';
import AuthControl from '../../auth/AuthControl';
import '../../../theme/global.css';
import { getNotificationCount } from '../../../api/notificationService';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../config/firebase-config';
import { io } from 'socket.io-client';
import { useActionMessage } from '../../common/actionMessage/ActionMessage';
import InboxIcon from '@mui/icons-material/Inbox';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HelpIcon from '@mui/icons-material/Help';
import AddCircleIcon from '@mui/icons-material/AddCircle';

interface NavbarProps {
  bannerHeight: number; // Use this if you need to adjust top margin
}

const socket = io(process.env.REACT_APP_API_BASE_URL as string, {});

const Navbar: React.FC<NavbarProps> = ({ bannerHeight }) => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const { showMessage } = useActionMessage();

  useEffect(() => {
    if (!user) {
      setNotificationCount(0);
      return;
    }

    const getNotifications = async () => {
      try {
        const count = await getNotificationCount();
        if (count > 0) {
          showMessage(
            `You have ${count} unread notifications. View Notifications in Inbox.`,
            'info'
          );
        }
        setNotificationCount(count);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    const initializeSocket = () => {
      if (!socket.connected) {
        socket.connect();
      }

      socket.emit('joinNotifications', user.uid);

      const handleNewNotification = async () => {
        const count = await getNotificationCount();
        setNotificationCount(count);
        showMessage('New Notification! View Notification in Inbox', 'info');
      };

      const handleNotificationRead = () => {
        setNotificationCount((prev) => Math.max(Number(prev) - 1, 0));
      };

      socket.on('notification:new', handleNewNotification);
      socket.on('notification:read', handleNotificationRead);

      return () => {
        socket.off('notification:new', handleNewNotification);
        socket.off('notification:read', handleNotificationRead);
      };
    };

    getNotifications();
    const cleanupSocketListeners = initializeSocket();

    return () => {
      if (cleanupSocketListeners) cleanupSocketListeners();
      socket.disconnect();
    };
  }, [user]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        // You can add your own padding here if needed
        px: { xs: 2, sm: 8 },
        pt: bannerHeight ? 8 : 6,
      }}
    >
      {/* Left side – Logo */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          '&:hover': { opacity: 0.7 },
        }}
        onClick={() => navigate('/', { state: { resetPage: true } })}
      >
        <Box
          component="img"
          src="https://d1athdg3jj0a20.cloudfront.net/compressed/assets/Dublease_Logo.webp"
          alt="Dublease Logo"
          sx={{
            width: { xs: '50px', sm: '70px' },
            display: { xs: 'none', sm: 'block' },
            mr: 1,
          }}
        />
        <Box display="flex" flexDirection="column">
          <Box
            component="img"
            src="https://d1athdg3jj0a20.cloudfront.net/compressed/assets/Dublease_Title_Blue.webp"
            alt="Dublease Title"
            sx={{ width: '180px', mt: '3px' }}
          />
          <Box
            component="img"
            src="https://d1athdg3jj0a20.cloudfront.net/compressed/assets/subleasing_for_college_students.webp"
            alt="Subleasing for College Students"
            sx={{ width: '250px', mt: '3px' }}
          />
        </Box>
      </Box>

      {/* Right side – Desktop menu */}
      <Box
        sx={{
          display: { xs: 'none', md: 'flex' },
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Button
          onClick={() => navigate('/ListYourPlace')}
          sx={{ fontSize: '15px' }}
        >
          List Your Place
        </Button>
        <Button onClick={() => navigate('/Profile')} sx={{ fontSize: '15px' }}>
          Profile
        </Button>
        {notificationCount > 0 ? (
          <Badge
            badgeContent={notificationCount}
            color="error"
            overlap="circular"
          >
            <Button
              onClick={() => navigate('/Inbox')}
              sx={{ fontSize: '15px' }}
            >
              Inbox
            </Button>
          </Badge>
        ) : (
          <Button onClick={() => navigate('/Inbox')} sx={{ fontSize: '15px' }}>
            Inbox
          </Button>
        )}
        <Button component={Link} to="/Support" sx={{ fontSize: '15px' }}>
          Support
        </Button>
        <Divider orientation="vertical" flexItem />
        <AuthControl />
      </Box>

      {/* Mobile view – Hamburger menu */}
      <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
        <IconButton color="inherit" onClick={() => setDrawerOpen(true)}>
          <MenuIcon />
        </IconButton>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          PaperProps={{
            sx: {
              width: '75%',
              maxWidth: '320px',
              p: 1,
              backgroundColor: 'rgba(255,255,255,0.95)',
              backdropFilter: 'blur(12px)',
            },
          }}
        >
          <Box display="flex" alignItems="center" mb={2}>
            <Box
              component="img"
              src="https://d1athdg3jj0a20.cloudfront.net/compressed/assets/Dublease_Logo_And_Title.webp"
              alt="Dublease Logo"
              sx={{ width: '170px', height: 'auto', mr: 1 }}
            />
          </Box>
          <Divider sx={{ mb: 2 }} />
          <MenuItem
            onClick={() => {
              navigate('/ListYourPlace');
              setDrawerOpen(false);
            }}
          >
            <AddCircleIcon sx={{ mr: 1 }} />
            List Your Place
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate('/Profile');
              setDrawerOpen(false);
            }}
          >
            <AccountCircleIcon sx={{ mr: 1 }} />
            Profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate('/Inbox');
              setDrawerOpen(false);
            }}
          >
            {notificationCount > 0 ? (
              <Badge
                badgeContent={notificationCount}
                color="error"
                overlap="circular"
              >
                <InboxIcon sx={{ mr: 1 }} />
              </Badge>
            ) : (
              <InboxIcon sx={{ mr: 1 }} />
            )}
            <Typography variant="inherit" ml={notificationCount > 0 ? 1 : 0}>
              Inbox
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate('/Support');
              setDrawerOpen(false);
            }}
          >
            <HelpIcon sx={{ mr: 1 }} />
            Support
          </MenuItem>
          <Divider sx={{ my: 2 }} />
          <MenuItem
            onClick={() => {
              navigate('/TermsOfService');
              setDrawerOpen(false);
            }}
          >
            Terms of Service
          </MenuItem>
          <AuthControl onClose={() => setDrawerOpen(false)} />
        </Drawer>
      </Box>
    </Box>
  );
};

export default Navbar;
