export interface Filters {
    ownedListings: boolean;
    activeOffers: boolean;
    startDate: string | null;
    endDate: string | null;
    location: string | null;
    latitude: number | null;
    longitude: number | null;
    numBeds: number | null;
    numRoommates: number | null;
    sortPrice: string | null;
    available: string | null;
}

export const defaultFilters: Filters = {
    ownedListings: false,
    activeOffers: false,
    startDate: null,
    endDate: null,
    location: null,
    latitude: null,
    longitude: null,
    numBeds: null,
    numRoommates: null,
    sortPrice: null,
    available: null,
  };